import React, { useEffect, useState } from "react"
import { Box } from "theme-ui"
import { Heading } from "../../components/Heading/Heading"
import { Text } from "../../components/Text/Text"
import { Container, Row, Column } from "../../components/Grid"
import { Success } from "../../components/Steps/Success"
import { Header } from "../../components/Header/Header"
import { Footer } from "../../components/Footer/Footer"
import { getUrlParams } from "../../common/format"
import { Loading } from "../../components/Loading/Loading"

const ConfirmContainer = props => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const { id = null } = getUrlParams(props.location.search)

    if (id) {
      fetch("/.netlify/functions/processPayment", {
        method: "POST",
        body: JSON.stringify({
          id,
        }),
      })
        .then(function(response) {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response
        })
        .then(a => a.json())
        .then(a => {
          console.log(a, "loadingloading")
          if (!a.isSuccess) {
            setError(a.description)
          }

          setLoading(false)
        })
        .catch(e => {
          setLoading(false)
          setError(e.message)
        })
    } else {
      setLoading(false)
    }
  }, [])

  const {
    data: { contentfulPages: page },
  } = props

  return (
    <>
      <Header />
      <Container>
        {loading ? (
          <Loading />
        ) : error ? (
          <>
            <Row alignItems={["flex-start"]}>
              <Column
                sx={{
                  flex: "0 0 auto",
                  width: ["100%", "100%", "100%", "80%"],
                }}
              >
                <Heading variant="hero" mb={[3]} color="brandRed">
                  Transaction Error
                </Heading>
              </Column>
            </Row>
            <Row
              sx={{
                justifyContent: "space-between",
                flexDirection: ["column", "column", "row"],
              }}
            >
              <Column
                sx={{
                  flex: "0 0 auto",
                  width: ["100%", "100%", "50%"],
                  order: [1, 1, 0],
                }}
              >
                <Box width={"100%"}>
                  <Text mb={[2]}>{error}</Text>
                </Box>
              </Column>
            </Row>
          </>
        ) : (
          <>
            <Row alignItems={["flex-start"]}>
              <Column
                sx={{
                  flex: "0 0 auto",
                  width: ["100%", "100%", "100%", "80%"],
                }}
              >
                <Heading variant="hero" mb={[3]} color="textDark">
                  {page.confirmationHeadline}
                </Heading>
              </Column>
            </Row>
            <Row
              sx={{
                justifyContent: "space-between",
                flexDirection: ["column", "column", "row"],
              }}
            >
              <Column
                sx={{
                  flex: "0 0 auto",
                  width: ["100%", "100%", "50%"],
                  order: [1, 1, 0],
                }}
              >
                <Box>
                  <Text
                    mb={[2]}
                    dangerouslySetInnerHTML={{
                      __html: page.confirmationMessage.childMarkdownRemark.html,
                    }}
                  ></Text>
                </Box>
              </Column>
              <Column
                sx={{
                  flex: "0 0 auto",
                  width: ["100%", "100%", "40%"],
                  order: [0, 0, 1],
                }}
              >
                <Box
                  bg="bgLight"
                  p={[3]}
                  sx={{
                    borderRadius: "10px",
                    width: "100%",
                    mb: [2],
                    alignSelf: "flex-start",
                    position: ["relative", "relative", "sticky"],
                    top: [0, 0, 2],
                  }}
                >
                  <Success page={page} />
                </Box>
              </Column>
            </Row>
          </>
        )}
      </Container>
      <Footer bg="bgDark" />
    </>
  )
}

export default ConfirmContainer

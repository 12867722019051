import React from "react"

import { Box } from "theme-ui"
import { Heading } from "../../components/Heading/Heading"
import { Text } from "../../components/Text/Text"
import { Container, Row, Column } from "../../components/Grid"
import { Header } from "../../components/Header/Header"
import { Footer } from "../../components/Footer/Footer"
import { contentApi } from "../../common/utils/language"

import { Video } from "../../components/Video/Video"
import { DonateContainer } from "../Donate/DonateContainer"
import { PetitionContainer } from "../Petition/PetitionContainer"

const PageContainer = ({
  data: { contentfulPages: page },
  location,
  navigate,
}) => {
  const FormComponent =
    page.instanceType === "petition" ? PetitionContainer : DonateContainer

  return (
    <>
      <Header />
      <Container>
        <Row alignItems="flex-start">
          <Column
            sx={{ flex: "0 0 auto", width: ["100%", "100%", "100%", "80%"] }}
          >
            <Heading as="h1" variant="hero" mb={[3]} color="textDark">
              {page.headline}
            </Heading>
          </Column>
        </Row>
        <Row
          sx={{
            justifyContent: "space-between",
            flexDirection: ["column", "column", "row"],
          }}
        >
          <Column
            sx={{
              flex: "0 0 auto",
              width: ["100%", "100%", "50%"],
              order: [1, 1, 0],
            }}
          >
            <Box>
              {page.videoId ? (
                <Video mb={[2]} videoId={page.videoId || contentApi.videoId} />
              ) : null}
              <Text
                mb={[2]}
                css={`
                  img {
                    max-width: 100%;
                  }
                `}
                dangerouslySetInnerHTML={{
                  __html: page.content.childMarkdownRemark.html,
                }}
              ></Text>
            </Box>
          </Column>
          <Column
            sx={{
              flex: "0 0 auto",
              width: ["100%", "100%", "40%"],
              order: [0, 0, 1],
            }}
          >
            <Box
              bg="bgLight"
              p={[3]}
              sx={{
                borderRadius: "10px",
                width: "100%",
                mb: [3, 2],
                alignSelf: "flex-start",
                position: ["relative", "relative"],
                top: [0, 0, 2],
              }}
            >
              <Heading variant="primary" mb={[2, 3]}>
                {page.formTitle}
              </Heading>

              <FormComponent
                page={page}
                location={location}
                navigate={navigate}
              />
            </Box>
          </Column>
        </Row>
      </Container>
      <Footer bg="bgDark" />
    </>
  )
}

export default PageContainer

import React from "react"
import { Box } from "theme-ui"
import { Heading } from "../Heading/Heading"
import { language } from "../../common/utils/language"
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
export const Success = ({ page }) => {
  const { domain, slug, twitterText, whatsApp, trackingId } = page

  const getDomain = network => {
    return `${domain}/${slug}?c=${trackingId}-${network}`
  }

  return (
    <>
      <Heading variant="primary" mb={[1, 3]}>
        {page.meta.socialHeadline || language.success.headline}
      </Heading>

      <Box mt={[3]}>
        <Box mb={[1]}>
          <EmailShareButton url={getDomain("E")}>
            <i className="icon icon-envelope" /> {language.success.buttonEmail}
          </EmailShareButton>
        </Box>
        <Box mb={[1]}>
          <FacebookShareButton url={getDomain("FB")}>
            <i className="icon icon-facebook" />{" "}
            {language.success.buttonFacebook}
          </FacebookShareButton>
        </Box>
        <Box mb={[1]}>
          <TwitterShareButton url={getDomain("TW")} title={twitterText}>
            <i className="icon icon-twitter" /> {language.success.buttonTwitter}
          </TwitterShareButton>
        </Box>
        <Box mb={[1]}>
          <WhatsappShareButton
            separator="-"
            url={getDomain("WA")}
            title={whatsApp}
          >
            <i className="icon icon-whatsapp" />{" "}
            {language.success.buttonWhatsapp}
          </WhatsappShareButton>
        </Box>
      </Box>
    </>
  )
}

import React, { useState, useEffect } from "react"

import { Flex, Box, Label, Input, Checkbox } from "theme-ui"

import { language } from "../../common/utils/language"
import { Text } from "../Text/Text"
import { Button } from "../Button/Button"

const amounts = [
  {
    label: "R 10",
    amount: 10,
  },
  {
    label: "R 25",
    amount: 25,
  },
  {
    label: "R 50",
    amount: 50,
  },
  {
    label: "R 100",
    amount: 100,
  },
  {
    label: "R 200",
    amount: 200,
  },
  {
    label: "R 500",
    amount: 500,
  },
]
export const Donation = ({ errors, onSubmit, page, amount, onSetStep }) => {
  const [other, setOther] = useState()
  const [recurring, setRecurring] = useState(true)

  let amountList = page.meta.amounts
    ? page.meta.amounts.map(a => ({ label: `R ${a}`, amount: a }))
    : amounts

  const [value, setValue] = useState()

  if (amount) {
    if (!amountList.some(p => p.amount === parseFloat(amount))) {
      amountList.push({
        label: `R ${
          Math.floor(parseFloat(amount)) === parseFloat(amount)
            ? amount
            : parseFloat(amount).toFixed(2)
        }`,
        amount: parseFloat(amount),
      })
    }
  }

  useEffect(() => {
    if (amount) {
      if (
        amount &&
        amountList.some(p => parseFloat(p.amount) === parseFloat(amount))
      ) {
        setValue(parseFloat(amount))
      }
    } else {
      setValue(parseFloat(amountList[0].amount))
    }
  }, [amount])

  return (
    <>
      <Label mb={[1, 2]}>{language.donation.labelAmount}</Label>
      <Flex sx={{ flexDirection: "column" }}>
        <Flex
          mb={[1]}
          sx={{ justifyContent: "space-between", flexWrap: "wrap" }}
        >
          {amountList
            .sort((a, b) => (a.amount > b.amount ? 1 : -1))
            .map(amt => {
              return (
                <Button
                  sx={{ width: ["49%", "49%", "49%", "30%"], mb: [2] }}
                  variant={
                    value === amt.amount && !other
                      ? "outline.active"
                      : "outline.default"
                  }
                  onClick={() => setValue(amt.amount)}
                >
                  {amt.label}
                </Button>
              )
            })}
        </Flex>
      </Flex>
      <Box mb={[2]}>
        <Label sx={{ alignItems: "center" }}>
          {language.donation.labelAmountOther}
        </Label>
        <Input
          title="other"
          onChange={e => setOther(parseFloat(e.target.value) || "")}
          value={other}
        />
      </Box>
      <Box mb={[2]} p={[2]} bg={"#eee"}>
        <Label>
          <Checkbox
            checked={recurring}
            onChange={e => {
              setRecurring(e.target.checked)
            }}
          />
          {language.donation.labelRecurring}
        </Label>
      </Box>

      <Box mt={[3]}>
        <Button
          isBlock
          variant="primary.default"
          onClick={() =>
            onSubmit({
              recurring,
              value: value ? parseFloat(value).toFixed(2) : null,
              other: other ? parseFloat(other).toFixed(2) : null,
            })
          }
        >
          {language.donation.buttonNext}
        </Button>
      </Box>
      {errors && errors.amount ? (
        <Box mt={[3]} bg="brandRed">
          <Text>{errors.amount}</Text>
        </Box>
      ) : null}

      <Box mt={[3]}>
        <hr />
        <Button variant={"link.default"} onClick={() => onSetStep(4)}>
          {language.paymentInfo.eftOption}
        </Button>
      </Box>
    </>
  )
}

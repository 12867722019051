import React, { useState } from "react"
import { Box, Label, Input } from "theme-ui"
import { Button } from "../../../components/Button/Button"
import { language } from "../../../common/utils/language"
import { ContactInfo } from "../../../components/Steps/ContactInfo"
import { InputValidator } from "../../../common/format"

const OfflineEft = ({ onSetStep, page }) => {
  const [step, setStep] = useState("contact")
  const [errors, setErrors] = useState(0)
  const [reference, setReference] = useState(0)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({ resident: true })

  const onSubmit = () => {
    setLoading(true)
    setErrors({})

    const validEmail = new InputValidator(form.email).validateEmail()
    const validCellphone = new InputValidator(
      form.cellphone
    ).validateCellphone()
    //const validId = new InputValidator(form.idnumber).validateIdNumber()

    const errors = {}

    if (!form.name) {
      errors.name = "Name is required"
    }

    if (!form.email) {
      errors.email = "E-mail address is required"
    } else if (!validEmail) {
      errors.email = "Invalid e-mail address"
    }

    if (!form.cellphone) {
      errors.cellphone = "Cellphone number is required"
    }

    if (form.resident) {
      /*
        if (!form.idnumber) {
          errors.idnumber = "ID Number number is required"
        } else if (!validId) {
          errors.idnumber = "ID Number number is invalid"
        }*/
    }

    if (!form.consent) {
      errors.consent = "Required"
    }

    if (Object.keys(errors).length) {
      setErrors(errors)
      setLoading(false)
      return
    }
    fetch("/.netlify/functions/eftCheckout", {
      method: "POST",
      body: JSON.stringify(form),
    })
      .then(response => response.json())
      .then(resp => {
        setLoading(false)
        setReference(resp.reference)
        setStep("details")
      })
      .then(console.log)
  }

  if (step === "contact") {
    return (
      <ContactInfo
        loading={loading}
        title={"Generate Reference for EFT"}
        onSetStep={setStep}
        onSetForm={setForm}
        form={form}
        errors={errors}
        onSubmit={onSubmit}
      ></ContactInfo>
    )
  }

  return (
    <Box mb={[2]}>
      <Box mb={[1]}>
        <Label>Account Name</Label>
        <Input sx={{ padding: "4px 8px" }} value="ActionSA" readOnly />
      </Box>
      <Box mb={[1]}>
        <Label>Account Type</Label>
        <Input
          sx={{ padding: "4px" }}
          value="Nedbank Current Account"
          readOnly
        />
      </Box>
      <Box mb={[1]}>
        <Label>Account Number</Label>
        <Input sx={{ padding: "4px" }} value="1211229459" readOnly />
      </Box>
      <Box mb={[1]}>
        <Label>Branch Code</Label>
        <Input sx={{ padding: "4px" }} value="198765" readOnly />
      </Box>
      <Box mb={[1]}>
        <Label>Swift Code</Label>
        <Input sx={{ padding: "4px" }} value="NEDSZAJJ" readOnly />
      </Box>

      <Box mb={[1]}>
        <Label>Reference</Label>
        <Input sx={{ padding: "4px" }} value={reference} readOnly />
      </Box>

      <Box mt={[3]}>
        <hr />
        <Button variant={"link.default"} onClick={() => onSetStep(0)}>
          {language.paymentInfo.ccOption}
        </Button>
      </Box>
    </Box>
  )
}

export default OfflineEft

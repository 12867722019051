import { parsePhoneNumber, NumberFormat } from "libphonenumber-js"

export const getUrlParams = search => {
  const hashes = search.slice(search.indexOf("?") + 1).split("&")
  const params = {}
  hashes.map(hash => {
    const [key, val] = hash.split("=")
    params[key] = decodeURIComponent(val)
  })
  return params
}

export class InputValidator {
  constructor(payload = "") {
    this.input = payload.toLowerCase().trim()
  }

  validateIdNumber() {
    let correct = true

    if (this.input.length !== 13) {
      return false
    }

    let tempTotal = 0
    let checkSum = 0
    let multiplier = 1
    for (let i = 0; i < 13; ++i) {
      tempTotal = parseInt(this.input.charAt(i), 10) * multiplier
      if (tempTotal > 9) {
        tempTotal =
          parseInt(tempTotal.toString().charAt(0), 10) +
          parseInt(tempTotal.toString().charAt(1), 10)
      }
      checkSum = checkSum + tempTotal
      multiplier = multiplier % 2 === 0 ? 1 : 2
    }

    if (checkSum % 10 !== 0) {
      correct = false
    }

    return correct
  }

  validateEmail() {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.input)
  }

  validateCellphone() {
    try {
      const phoneNumber = this.input

      const countryCode = "ZA"
      const number = parsePhoneNumber(phoneNumber, countryCode)

      return number.isValid()
    } catch {
      return false
    }
  }

  formatCellphone(format) {
    const phoneNumber = this.input
    const countryCode = "ZA"
    return parsePhoneNumber(phoneNumber, countryCode).format(format)
  }
}

import React, { useState } from "react"
import { getUrlParams } from "../../common/format"
import { Box, Label, Input, Textarea } from "theme-ui"
import { Button } from "../../components/Button/Button"
import { language } from "../../common/utils/language"
import { Text } from "../../components/Text/Text"

const formatForm = ({ fields = [], labels = [] }) => {
  return fields.map((name, index) => {
    return {
      label: labels[index],
      name: name,
    }
  })
}

const FieldBuilder = ({ name, label, onChange, value, error }) => {
  console.log(error)
  switch (name) {
    case "cellphone":
      return (
        <Box mb={[2]}>
          <Label>{label}</Label>
          <Input
            type={"telephone"}
            onChange={e => onChange(name, e.target.value)}
            value={value || ""}
          />
          {error ? (
            <Text variant="small" color="brandRed">
              {error}
            </Text>
          ) : null}
        </Box>
      )
    case "email":
      return (
        <Box mb={[2]}>
          <Label>{label}</Label>
          <Input
            type={"email"}
            onChange={e => onChange(name, e.target.value)}
            value={value || ""}
          />
          {error ? (
            <Text variant="small" color="brandRed">
              {error}
            </Text>
          ) : null}
        </Box>
      )
    case "message":
      return (
        <Box mb={[2]}>
          <Label>{label}</Label>
          <Textarea
            rows={3}
            sx={{ background: "#fff" }}
            onChange={e => onChange(name, e.target.value)}
            value={value || ""}
          />
          {error ? (
            <Text variant="small" color="brandRed">
              {error}
            </Text>
          ) : null}
        </Box>
      )

    default:
      return (
        <Box mb={[2]}>
          <Label>{label}</Label>
          <Input
            type={"input"}
            onChange={e => onChange(name, e.target.value)}
            value={value || ""}
          />
          {error ? (
            <Text variant="small" color="brandRed">
              {error}
            </Text>
          ) : null}
        </Box>
      )
  }
}

export const PetitionContainer = ({ page, location, navigate }) => {
  console.log(page)
  const { name = "", email = "", c = null } = getUrlParams(location.search)
  const [formData, setFormData] = useState({
    name,
    email,
    reference: c,
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const onFormValueSet = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    })
  }

  const validateForm = values => {
    const errors = {}
    const { fields, required } = page.meta.form

    fields.forEach(field => {
      if (required.includes(field) && !values[field]) {
        errors[field] = language.form.required
      } else if (
        values[field] &&
        field === "cellphonwe" &&
        !/^0(6[0-9]|7[0-46-9]|8[1-4])\d{7}$/i.test(values[field])
      ) {
        errors[field] = language.form.validateCellphone
      } else if (
        values[field] &&
        field === "email" &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[field])
      ) {
        errors[field] = language.form.validateEmail
      }
    })

    return {
      errors,
      hasErrors: Object.keys(errors).length,
    }
  }
  const onSubmit = e => {
    e.preventDefault()
    setErrors({})
    setLoading(true)

    const { hasErrors, errors } = validateForm(formData)
    console.log(errors)
    if (hasErrors) {
      setLoading(false)
      setErrors(errors)
      return
    }

    fetch(`${process.env.GATSBY_API_URL}/petition/events`, {
      method: "POST",
      headers: {
        application: 1,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "SUBMIT_PETITION",
        payload: {
          form: {
            ...formData,
          },
          campaignId: page.contentful_id,
        },
      }),
    })
      .then(response => response.json())
      .then(({ error }) => {
        setLoading(false)
        if (error) {
          setErrors({
            form: error,
          })
        } else {
          navigate(`/${page.slug}/confirm`)
        }
      })
      .catch(err => {
        setLoading(false)
        setErrors({
          form: "Something went wrong. Please try again.",
        })
      })
  }

  if (!page.meta.form) return null
  const fields = formatForm(page.meta.form)

  return (
    <form onSubmit={onSubmit}>
      {fields.map(field => (
        <FieldBuilder
          {...field}
          error={errors[field.name] || null}
          onChange={onFormValueSet}
          value={formData[field.name] || ""}
        />
      ))}
      <Box mt={[3]}>
        <Button isBlock variant="primary.default" disabled={loading}>
          {loading
            ? language.loading
            : page.meta.form.submit || language.petition.buttonNext}
        </Button>
      </Box>
    </form>
  )
}

import React from "react"
import PropTypes from "prop-types"
import { Flex } from "theme-ui"
import { Text } from "../Text/Text"

const sizeMap = {
  small: "small",
  medium: "body",
  large: "secondary",
}

export const Loading = props => {
  const { minHeight, size, color, label } = props

  return (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        minHeight: minHeight ? minHeight : "0",
      }}
    >
      <Text variant={sizeMap[size]} color={color}>
        <i className="icon icon-loading icon-spin" />
      </Text>
      {label && (
        <Text variant={sizeMap[size]} color={color} ml={[1]}>
          {label}
        </Text>
      )}
    </Flex>
  )
}

Loading.propTypes = {
  minHeight: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizeMap)),
}

Loading.defaultProps = {
  minHeight: null,
  label: "Loading",
  color: "#000",
  size: "medium",
}

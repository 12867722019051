import React from "react"
import PageContainer from "../modules/Page/PageContainer"
import { graphql } from "gatsby"
import ConfirmContainer from "../modules/Page/ConfirmContainer"
import SEO from "../components/SEO"
import Layout from "../components/App"

const TEMPLATE_MAP = {
  page: PageContainer,
  confirm: ConfirmContainer,
}
const PageTemplate = ({ pathContext, ...rest }) => {
  const {
    data: { contentfulPages: page },
  } = rest

  const seoPage = {
    title: page.headline,
    description: page.content.childMarkdownRemark.excerpt,
    image: page.image ? page.image.file.url : null,
    url: page.domain
      ? `${page.domain}/${page.slug}/`
      : `${process.env.URL}/${page.slug}/`,
  }

  if (pathContext.template) {
    const Component = TEMPLATE_MAP[pathContext.template]
    if (Component) {
      return (
        <Layout>
          <SEO {...seoPage}></SEO>
          <Component {...rest} />
        </Layout>
      )
    }
  }

  if (page.template) {
    const Component = TEMPLATE_MAP[page.template]
    if (Component) {
      return (
        <Layout>
          <SEO {...seoPage}></SEO>
          <Component {...rest} />
        </Layout>
      )
    }
  }

  return (
    <Layout>
      <SEO {...seoPage}></SEO>
      <PageContainer {...rest} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageBySlug($slug: String) {
    contentfulPages(slug: { eq: $slug }) {
      id
      contentful_id
      domain
      headline
      formTitle
      whatsApp
      slug
      whatsAppUrl
      twitterText
      trackingId
      videoId
      instanceType
      content {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      confirmationMessage {
        childMarkdownRemark {
          html
        }
      }
      confirmationHeadline
      image {
        fluid {
          src
        }
        file {
          url
        }
      }
      meta {
        amounts
        form {
          fields
          required
          labels
        }
      }
    }
  }
`

export default PageTemplate

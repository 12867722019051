import React from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"
import styled from "@emotion/styled"
import { Box } from "theme-ui"

const VideoWrapper = styled(Box)`
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
`

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`

export const Video = props => {
  const { videoId, ...rest } = props

  return (
    <VideoWrapper {...rest}>
      <StyledReactPlayer
        width="100%"
        height="100%"
        url={`https://home.wistia.com/medias/${videoId}`}
        playing={false}
      />
    </VideoWrapper>
  )
}

Video.propTypes = {
  videoId: PropTypes.string,
}

Video.defaultProps = {
  videoId: null,
}

import React from "react"
import { Global, css } from "@emotion/core"

export default ({ children }) => (
  <>
    <Global
      styles={theme => css`
        body {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        .wpwl-form {
          background: transparent;
        }
        .wpwl-label {
          ${theme.text.body};
          font-weight: bold;
        }
        .wpwl-control {
          /* padding: 14px;
          padding-bottom: 8px;
          padding-top: 8px; */
          border-radius: 4px;
          border-color: ${theme.colors.borderDark};
        }
        .wpwl-button {
          font-weight: 700;
          border-radius: 8px;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 32px;
          padding-right: 32px;
        }
        .wpwl-group.wpwl-group-brand {
          position: relative;
          /* display: flex;
          align-items: center; */
        }
      `}
    />
    {children}
  </>
)

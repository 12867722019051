import React, { useEffect } from "react"
import { renderToString } from "react-dom/server"
import $ from "jquery"
import { Loading } from "../../../components/Loading/Loading"
import useScript from "../../../hooks/useScripts"
import { language } from "../../../common/utils/language"
import { Box, Label } from "theme-ui"
import { Button } from "../../../components/Button/Button"

const FORM_URL = process.env.GATSBY_FORM_URL + "/confirm"

// Usage
const PeachInput = ({ label, placeholder, name }) => {
  return (
    <div className="wpwl-group wpwl-group-cardHolder wpwl-clearfix">
      <div className="wpwl-label wpwl-label-cardHolder">{label}</div>
      <div className="wpwl-wrapper wpwl-wrapper-email">
        <input
          type="email"
          className="wpwl-control"
          name={name}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}

const PeachHidden = ({ value, name }) => {
  return <input type="hidden" name={name} value={value} />
}

const Checkout = ({ checkoutId, page, campaign, onSetStep, form }) => {
  console.log(checkoutId)
  const [loaded, error] = useScript(
    `${process.env.GATSBY_PEACH_SCRIPT_URL}/v1/paymentWidgets.js?checkoutId=${checkoutId}`
  )
  const brands = "VISA MASTER"
  useEffect(() => {
    const formLanguage = {
      "wpwl-label-brand": language.brand,
      "wpwl-label-cardNumber": language.cardNumber,
      "wpwl-label-expiry": language.expiry,
      "wpwl-label-cardHolder": language.cardHolder,
      "wpwl-button-pay": language.submit,
    }

    const fields = [
      {
        name: "customParameters[SHOPPER_campaign]",
        value: page.id,
        render: props => renderToString(<PeachHidden {...props} />),
      },
      {
        name: "customParameters[SHOPPER_reference]",
        value: campaign,
        render: props => renderToString(<PeachHidden {...props} />),
      },
      {
        name: "customParameters[SHOPPER_campaign_name]",
        value: page.slug,
        render: props => renderToString(<PeachHidden {...props} />),
      },
      {
        name: "customParameters[SHOPPER_campaign_id]",
        value: page.contentful_id,
        render: props => renderToString(<PeachHidden {...props} />),
      },
    ]

    window.wpwlOptions = {
      style: "plain",
      onReady: function() {
        Object.keys(formLanguage).forEach(element => {
          $(`.${element}`).html(formLanguage[element])
        })
        fields.forEach(({ render, ...props }) => {
          $("form.wpwl-form-card")
            .find(".wpwl-group-submit")
            .before(render(props))
        })
      },
    }
  }, [loaded])

  return (
    <div>
      <Label mb={[3]}>Donation: R {form.other ? form.other : form.value}</Label>
      <form action={FORM_URL} className="paymentWidgets" data-brands={brands}>
        <Loading label={"Loading Checkout"} minHeight="500px" />
      </form>
      <Box mt={[3]}>
        <hr />
        <Button variant={"link.default"} onClick={() => onSetStep(2)}>
          {language.paymentInfo.eftOption}
        </Button>
      </Box>
    </div>
  )
}

export default Checkout

import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import twitter from "../common/images/Donate_to_stop_the_spread.jpg"
const SEO = ({ title, description, image, url, twitterUsername, article }) => {
  return (
    <>
      <Helmet title={title}>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        {url && <meta property="og:url" content={url} />}
        {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}
        {title && <meta property="og:title" content={title} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {image && <meta property="og:image" content={image} />}
        <meta name="twitter:card" content="summary_large_image" />
        {twitterUsername && (
          <meta name="twitter:creator" content={twitterUsername} />
        )}
        {title && <meta name="twitter:title" content={title} />}
        {description && (
          <meta name="twitter:description" content={description} />
        )}
        {image && <meta name="twitter:image" content={`https:${image}`} />}
      </Helmet>
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  url: null,
  twitterUsername: null,
}

import React, { useState } from "react"
import Checkout from "./ui/Checkout"
import { Donation } from "../../components/Steps/Donation"
import { getUrlParams, InputValidator } from "../../common/format"
import OfflineEft from "./ui/OfflineEft"
import { ContactInfo } from "../../components/Steps/ContactInfo"

export const DonateContainer = ({ page, location }) => {
  const { amount = 0, c = "" } = getUrlParams(location.search)
  const [checkoutId, setCheckoutId] = useState("")
  const [step, setStep] = useState(0)
  const [errors, setErrors] = useState(null)
  const [form, setForm] = useState({
    resident: true,
  })

  const onSubmit = () => {
    setErrors({})

    const validEmail = new InputValidator(form.email).validateEmail()
    const validCellphone = new InputValidator(
      form.cellphone
    ).validateCellphone()
    //const validId = new InputValidator(form.idnumber).validateIdNumber()

    const errors = {}

    if (!form.name) {
      errors.name = "Name is required"
    }

    if (!form.email) {
      errors.email = "E-mail address is required"
    } else if (!validEmail) {
      errors.email = "Invalid e-mail address"
    }

    if (!form.cellphone) {
      errors.cellphone = "Cellphone number is required"
    }

    if (form.resident) {
      /*
      if (!form.idnumber) {
        errors.idnumber = "ID Number number is required"
      } else if (!validId) {
        errors.idnumber = "ID Number number is invalid"
      }*/
    }

    if (!form.consent) {
      errors.consent = "Required"
    }

    if (Object.keys(errors).length) {
      setErrors(errors)
      return
    }
    fetch("/.netlify/functions/createCheckout", {
      method: "POST",
      body: JSON.stringify({
        ...form,
        amount: form.other ? form.other : form.value,
        frequency: form.recurring ? "monthly" : "onceOff",
      }),
    })
      .then(response => response.json())
      .then(resp => {
        setCheckoutId(resp.id)
        setStep(2)
      })
      .then(console.log)
  }

  const amt = form.other ? form.other : form.value || amount

  switch (step) {
    case 0:
      return (
        <>
          <Donation
            errors={errors}
            page={page}
            onSetStep={setStep}
            amount={amt}
            onSubmit={values => {
              if (!values.value && !values.other) {
                setErrors({
                  amount: "Select amount or enter donation amount",
                })
                return
              } else {
                setForm(form => ({ ...form, ...values }))
                setStep(1)
              }
            }}
          />
        </>
      )
    case 1:
      return (
        <>
          <ContactInfo
            onSetStep={setStep}
            onSetForm={setForm}
            form={form}
            errors={errors}
            onSubmit={onSubmit}
          ></ContactInfo>
        </>
      )
    case 2:
      return (
        <Checkout
          errors={errors}
          page={page}
          form={form}
          campaign={c}
          checkoutId={checkoutId}
          onSetStep={setStep}
        />
      )
    default:
    case 3:
      return <OfflineEft onSetStep={setStep} page={page} />
  }
}

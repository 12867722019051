import React from "react"
import { Button } from "../Button/Button"
import { Box, Label, Input, Text, Checkbox } from "theme-ui"
import { language } from "../../common/utils/language"

export const ContactInfo = props => {
  const {
    onSetForm,
    form,
    errors,
    onSubmit,
    onSetStep,
    loading,
    showAmount = false,
    showBack = false,
    title,
  } = props

  return (
    <>
      {title ? (
        <>
          <Label>{title}</Label>
          <hr />
        </>
      ) : null}
      <Box mb={[1]}>
        <Label>{language.contactInfo.labelName}</Label>
        <Input
          title="Name"
          onChange={e => onSetForm({ ...form, name: e.target.value })}
          value={form.name || ""}
        />
        {errors && errors.name ? (
          <Box>
            <Text color="brandRed" variant="text.small">
              {errors.name}
            </Text>
          </Box>
        ) : null}
      </Box>
      <Box mb={[1]}>
        <Label>{language.contactInfo.labelCellphone}</Label>
        <Input
          title="Name"
          onChange={e => onSetForm({ ...form, cellphone: e.target.value })}
          value={form.cellphone || ""}
        />
        {errors && errors.cellphone ? (
          <Box>
            <Text color="brandRed" variant="text.small">
              {errors.cellphone}
            </Text>
          </Box>
        ) : null}
      </Box>
      <Box mb={[1]}>
        <Label>{language.contactInfo.labelEmail}</Label>
        <Input
          title="Email"
          type="email"
          onChange={e => onSetForm({ ...form, email: e.target.value })}
          value={form.email || ""}
        />
        {errors && errors.email ? (
          <Box>
            <Text color="brandRed" variant="text.small">
              {errors.email}
            </Text>
          </Box>
        ) : null}
      </Box>
      {showAmount ? (
        <Box mb={[2]} p={[2]} bg={"#eee"}>
          <Label my={[1]}>
            Donation: R {form.other ? form.other : form.value}
          </Label>
        </Box>
      ) : null}
      <Text variant="text.small" mb={[1]}>
        The Political Party Funding Act requires donations to be used in a
        manner prescribed in the legislation.
      </Text>
      <Box mb={[1]}>
        <Label>
          <Checkbox
            checked={form.resident}
            onChange={e => onSetForm({ ...form, resident: e.target.checked })}
          />
          <Text
            variant="text.small"
            mb={[1]}
            sx={{ fontWeight: "normal", flex: 1 }}
          >
            {language.contactInfo.resident}
          </Text>
        </Label>
      </Box>
      <Box mb={[1]}>
        <Label>
          <Checkbox
            checked={form.consent}
            onChange={e => onSetForm({ ...form, consent: e.target.checked })}
          />

          <Text
            variant="text.small"
            mb={[1]}
            sx={{ fontWeight: "normal", flex: 1 }}
          >
            By ticking this box I confirm that the information I have provided
            is true and accurate, I make this donation in support of ActionSA,
            the funds donated are not the proceeds of any illegal activity.
          </Text>
        </Label>
        {errors && errors.consent ? (
          <Box>
            <Text color="brandRed" variant="text.small">
              Confirmation of information required
            </Text>
          </Box>
        ) : null}
      </Box>
      <Button
        disabled={loading}
        isBlock
        variant="secondary.default"
        onClick={onSubmit}
      >
        {loading ? "Loading..." : language.contactInfo.buttonNext}
      </Button>
      {showBack ? (
        <Button variant="link.small" onClick={() => onSetStep(0)}>
          Change Amount
        </Button>
      ) : null}
    </>
  )
}
